import { mapGetters } from 'vuex'
import DateRangePicker from 'vue2-daterange-picker'
import LineChart from '@/components/LineChart'
const Statistic = () => import('@/components/Statistic')
const SelectionBox = () => import('@/components/SelectionBox')

export default {
  data() {
    return {
      params: {
        dateContext: {
          startDate: new Date(),
          endDate: new Date()
        },
        stakingPlan: 0,
        groupBy: 1
      }
    }
  },

  components: {
    Statistic,
    SelectionBox,
    DateRangePicker,
    LineChart
  },

  computed: {
    ...mapGetters('staking', ['stakingPlanOptions']),

    ...mapGetters('report', ['formatStakingDataChart', 'formatStakingDataStatistics'])
  },

  methods: {
    getRequestParams(params) {
      const dateRangeRequest = this.$formatDateTime(params.dateContext).getDateRequest()

      return {
        stakingPlanId: params.stakingPlan,
        fromDate: dateRangeRequest.fromDate,
        toDate: dateRangeRequest.toDate
      }
    },

    getChartRequestParams(params = this.params) {
      const requestParams = this.getRequestParams(params)

      return {
        ...requestParams,
        type: this.params.groupBy
      }
    }
  }
}
